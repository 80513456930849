var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('b-card',{attrs:{"body-class":"py-4 px-3"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',[_vm._v(" Total "),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateGrandTotal, { symbol: 'Rp', precision: 0, thousand: '.' }))+" ("+_vm._s(_vm.calculatePercentage(_vm.calculateGrandTotal))+"%) ")])]),_c('b-col',[_vm._v(" Remaining "),_c('div',{staticClass:"remaining"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateRemaining, { symbol: 'Rp', precision: 0, thousand: '.' }))+" ("+_vm._s(_vm.calculatePercentage(_vm.calculateRemaining))+"%) ")])])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Year","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"text","clearable":false,"options":_vm.years},model:{value:(_vm.detail.year),callback:function ($$v) {_vm.$set(_vm.detail, "year", $$v)},expression:"detail.year"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Projection","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Projection","invalid-feedback":errors[0]}},[_c('b-input-group',{attrs:{"prepend":"Rp"}},[_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },on:{"input":function($event){return _vm.restrictInput($event)}},model:{value:(_vm.detail.projection),callback:function ($$v) {_vm.$set(_vm.detail, "projection", $$v)},expression:"detail.projection"}})],1)],1)]}}])})],1),_c('b-col',{staticClass:"mt-2 d-flex align-items-center",attrs:{"cols":"3"}},[_c('b-button',{staticClass:"reset",attrs:{"variant":"outline-warning","block":""},on:{"click":_vm.openCalculate}},[_vm._v(" Calculate ")])],1)],1)],1),_c('b-card',{staticClass:"mt-3",attrs:{"body-class":"py-4 px-3"}},[_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.detail.budgetTypes.length),expression:"!detail.budgetTypes.length"}]},[_c('b-col',[_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":require("@/assets/icon-no-invoice.png")}})])])],1),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.detail.budgetTypes.length),expression:"detail.budgetTypes.length"}],ref:"table",attrs:{"table-class":"table-budget-activity","items":_vm.detail.budgetTypes,"fields":_vm.fields,"striped":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-4"},[_c('b-spinner',{staticClass:"spinner-lg",attrs:{"variant":"primary"}})],1)]},proxy:true},{key:"cell(no)",fn:function(row){return [_c('b-link',{on:{"click":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}},[(!row.detailsShowing)?_c('img',{attrs:{"src":require("@/assets/add-toggle.svg")}}):_vm._e(),(row.detailsShowing)?_c('img',{attrs:{"src":require("@/assets/collaps-toggle.svg")}}):_vm._e()])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-table',{attrs:{"items":item.budgetActivities,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-4"},[_c('b-spinner',{staticClass:"spinner-lg",attrs:{"variant":"primary"}})],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(typeName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.budgetActivityName)+" ")]}},{key:"cell(budgetPercent)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('validation-provider',{attrs:{"name":"percent","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.budgetPercentage),expression:"item.budgetPercentage"}],staticClass:"form-control text-center",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0","type":"text"},domProps:{"value":(item.budgetPercentage)},on:{"change":function($event){return _vm.changeAmount(item)},"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "budgetPercentage", $event.target.value)},function($event){return _vm.restrictInputPercent($event, index)}]}})])]}}],null,true)})]}},{key:"cell(budgetRp)",fn:function(ref){
var item = ref.item;
return [_c('validation-provider',{attrs:{"name":"amount","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('money',{staticClass:"form-control text-center",class:{ 'is-invalid': errors.length },on:{"input":function($event){return _vm.restrictInputAmount('budgetAmount', item)}},model:{value:(item.budgetAmount),callback:function ($$v) {_vm.$set(item, "budgetAmount", $$v)},expression:"item.budgetAmount"}})],1)]}}],null,true)})]}},{key:"cell(utilized)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.utilized, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(remaining)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.remaining, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(january)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.january, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(february)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.february, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(march)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.march, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(april)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.april, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(may)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.may, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(june)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.june, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(july)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.july, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(august)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.august, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(september)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.september, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(october)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.october, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(november)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.november, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(december)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.december, { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}}],null,true)})]}},{key:"cell(budgetPercent)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateTotal(item.budgetActivities, 'budgetPercentage'))+" % ")]}},{key:"cell(budgetRp)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'budgetAmount'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(utilized)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'utilized'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(remaining)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'remaining'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(january)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'january'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(february)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'february'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(march)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'march'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(april)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'april'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(may)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'may'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(june)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'june'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(july)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'july'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(august)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'august'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(september)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'september'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(october)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'october'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(november)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'november'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(december)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateTotal(item.budgetActivities, 'december'), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}}])}),_c('b-row',{staticClass:"mt-4 mb-1"},[_c('b-col',[_c('b-button',{staticClass:"reset",attrs:{"variant":"outline-warning","block":""},on:{"click":_vm.goToList}},[_vm._v(" Cancel ")])],1),_c('b-col',[_c('b-button',{staticClass:"next",attrs:{"variant":"warning","block":""},on:{"click":_vm.saveBudget}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('modal-calculate',{on:{"set":_vm.setDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <validation-observer
      ref="form"
      slim
    >
      <b-card body-class="py-4 px-3">
        <b-row>
          <b-col cols="6">
            <b-row>
              <b-col>
                Total
                <div class="total">
                  {{ formatMoney(calculateGrandTotal, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
                  ({{ calculatePercentage(calculateGrandTotal) }}%)
                </div>
              </b-col>
              <b-col>
                Remaining
                <div class="remaining">
                  {{ formatMoney(calculateRemaining, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
                  ({{ calculatePercentage(calculateRemaining) }}%)
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" class="mt-1">
            <validation-provider
              name="Year"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Year"
                :invalid-feedback="errors[0]"
              >
                <custom-select
                  v-model="detail.year"
                  label="text"
                  :clearable="false"
                  :options="years"
                  :class="{ 'is-invalid': errors.length }"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="3">
            <validation-provider
              name="Projection"
              rules='required'
              v-slot="{ errors }"
            >
              <b-form-group
                label="Projection"
                :invalid-feedback="errors[0]"
              >
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    class="form-control"
                    v-model="detail.projection"
                    @input="restrictInput($event)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="3" class="mt-2 d-flex align-items-center">
            <b-button
              class="reset"
              variant="outline-warning"
              @click="openCalculate"
              block
            >
              Calculate
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-3" body-class="py-4 px-3">
        <b-row v-show="!detail.budgetTypes.length">
          <b-col>
            <div align="center">
              <img src="@/assets/icon-no-invoice.png"/>
            </div>
          </b-col>
        </b-row>
        <b-table
          ref="table"
          table-class="table-budget-activity"
          v-show="detail.budgetTypes.length"
          :items="detail.budgetTypes"
          :fields="fields"
          striped
          responsive
        >
          <template v-slot:table-busy>
            <div class="text-center my-4">
              <b-spinner
                variant="primary"
                class="spinner-lg"
              />
            </div>
          </template>
          <template #cell(no)="row">
            <b-link
              v-model="row.detailsShowing" @click="row.toggleDetails"
            >
              <img v-if="!row.detailsShowing" src="@/assets/add-toggle.svg"/>
              <img v-if="row.detailsShowing" src="@/assets/collaps-toggle.svg"/>
            </b-link>
          </template>
          <template #row-details="{ item }">
            <b-table
              :items="item.budgetActivities"
              :fields="fields"
              responsive
            >
              <template v-slot:table-busy>
                <div class="text-center my-4">
                  <b-spinner
                    variant="primary"
                    class="spinner-lg"
                  />
                </div>
              </template>
              <template #cell(no)="{ item, index }">
                {{ index + 1 }}
              </template>
              <template #cell(typeName)="{ item }">
                {{ item.budgetActivityName }}
              </template>
              <template #cell(budgetPercent)="{ item, index }">
                <validation-provider
                  name="percent"
                  rules="required|min_value:0|max_value:100"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    :invalid-feedback="errors[0]"
                  >
                    <input
                      class="form-control text-center"
                      placeholder="0"
                      v-model="item.budgetPercentage"
                      type="text"
                      @change="changeAmount(item)"
                      @input="restrictInputPercent($event, index)"
                      :class="{ 'is-invalid': errors.length }"
                    />
                  </b-form-group>
                </validation-provider>
              </template>
              <template #cell(budgetRp)="{ item }">
                <validation-provider
                  name="amount"
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                >
                  <b-form-group :invalid-feedback="errors[0]">
                    <money
                      class="form-control text-center"
                      v-model="item.budgetAmount"
                      @input="restrictInputAmount('budgetAmount', item)"
                      :class="{ 'is-invalid': errors.length }"
                    />
                  </b-form-group>
                </validation-provider>
              </template>
              <template #cell(utilized)="{ item }">
                {{ formatMoney(item.utilized, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(remaining)="{ item }">
                {{ formatMoney(item.remaining, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(january)="{ item }">
                {{ formatMoney(item.january, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(february)="{ item }">
                {{ formatMoney(item.february, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(march)="{ item }">
                {{ formatMoney(item.march, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(april)="{ item }">
                {{ formatMoney(item.april, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(may)="{ item }">
                {{ formatMoney(item.may, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(june)="{ item }">
                {{ formatMoney(item.june, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(july)="{ item }">
                {{ formatMoney(item.july, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(august)="{ item }">
                {{ formatMoney(item.august, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(september)="{ item }">
                {{ formatMoney(item.september, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(october)="{ item }">
                {{ formatMoney(item.october, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(november)="{ item }">
                {{ formatMoney(item.november, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
              <template #cell(december)="{ item }">
                {{ formatMoney(item.december, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
              </template>
            </b-table>
          </template>
          <template #cell(budgetPercent)="{ item }">
            {{ calculateTotal(item.budgetActivities, 'budgetPercentage') }} %
          </template>
          <template #cell(budgetRp)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'budgetAmount'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(utilized)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'utilized'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(remaining)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'remaining'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(january)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'january'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(february)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'february'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(march)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'march'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(april)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'april'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(may)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'may'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(june)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'june'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(july)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'july'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(august)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'august'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(september)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'september'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(october)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'october'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(november)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'november'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(december)="{ item }">
            {{ formatMoney(calculateTotal(item.budgetActivities, 'december'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
        </b-table>
        <b-row class="mt-4 mb-1">
          <b-col>
            <b-button
              class="reset"
              variant="outline-warning"
              block
              @click="goToList"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="next"
              variant="warning"
              block
              @click="saveBudget"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
    <modal-calculate @set="setDetail" />
  </div>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'
import ModalCalculate from './ModalCalculate.vue'

export default {
  props: {
  },

  components: {
    ModalCalculate
  },

  data: () => ({
    busy: false,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'typeName', label: 'Activities', thClass: 'text-center', thStyle: 'width: 200px' },
      { key: 'budgetPercent', label: 'Budget(%)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'budgetRp', label: 'Budget(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'utilized', label: 'Utillized(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remaining', label: 'Remaining(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'january', label: 'January(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'february', label: 'February(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'march', label: 'March(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'april', label: 'April(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'may', label: 'May(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'june', label: 'June(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'july', label: 'July(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'august', label: 'August(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'september', label: 'September(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'october', label: 'October(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'november', label: 'November(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'december', label: 'December(Rp)', tdClass: 'text-center', thClass: 'text-center' }
    ],
    years: ['2022', '2023'],
    detail: {
      year: null,
      projection: 0,
      budgetTypes: []
    },
    items: {}
  }),

  computed: {
    calculateGrandTotal() {
      const budgetActivities = this.detail.budgetTypes.map(v => v.budgetActivities)
      let total = 0
      budgetActivities.map(v => {
        v.map(d => {
          total += d.budgetAmount
        })
      })
      return total
    },
    calculateRemaining() {
      return this.detail.projection !== 0 ? this.detail.projection - this.calculateGrandTotal : 0
    }
  },

  created() {
    this.fetchViewBudget()
    this.fetchYears()
  },

  methods: {
    formatMoney,
    fetchYears() {
      let year = new Date().getFullYear()
      for (let i = 0; i < 5; i++) {
        this.years[i] = year++
      }
    },
    restrictInput(e) {
      if (e < 0 || this.detail.projection.toString().includes('-')) {
        const str = this.detail.projection.toString().replace('-', '')
        this.detail.projection = Number(str)
      }
    },
    restrictInputPercent(event, index, item) {
      if (/^\d*[.]?\d*$/.test(event.target.value)) {
        return true
      } else {
        this.detail.budgetTypes.map(v => {
          v.budgetActivities.forEach((d, i) => {
            if (i === index) {
              d.budgetPercentage = Number(d.budgetPercentage.toString().slice(0, -1))
            }
          })
        })
      }
    },
    changeAmount(item) {
      item.budgetAmount = this.detail.projection !== 0 ? item.budgetPercentage * this.detail.projection / 100 : 0
    },
    restrictInputAmount(key, item) {
      if (key === 'budgetAmount') {
        const val = this.detail.projection !== 0 ? item.budgetAmount * 100 / this.detail.projection : 0
        item.budgetPercentage = val % 1 !== 0 ? val.toFixed(2) : val
      } else {
        this.calculateAmount(item)
      }
    },
    async fetchViewBudget(id) {
      const { data } = await api.budget.viewBudget(id ?? this.$route.params.id)
      this.detail = data.data
      this.detail.budgetTypes.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
    },
    openCalculate() {
      this.$bvModal.show('modal-calculate')
    },
    calculateTotal(item, key) {
      if (!item.length) return 0
      let total = 0
      item.map(v => {
        total += Number(v[key])
      })
      return total % 1 !== 0 ? total.toFixed(2) : total
    },
    calculatePercentage(item, type) {
      if (type === 'activity') {
        item.budgetPercentage = this.detail.projection !== 0 ? item.budgetAmount * 100 / this.detail.projection : 0
        return item.budgetPercentage % 1 !== 0 ? item.budgetPercentage.toFixed(2) : item.budgetPercentage
      } else {
        const percent = this.detail.projection !== 0 ? item * 100 / this.detail.projection : 0
        return percent % 1 !== 0 ? percent.toFixed(2) : percent
      }
    },
    calculateAmount(item) {
      item.budgetAmount = this.detail.projection !== 0 ? item.budgetPercentage * this.detail.projection / 100 : 0
      return item.budgetAmount
    },
    async saveBudget() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        this.detail.budgetId = this.detail.id

        await api.budget.save(this.detail)
        this.$bvToast.toast('Success save new budget.', {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
        setTimeout(() => {
          this.goToList()
        }, 2500)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async setDetail(form) {
      const year = this.detail.year
      await this.fetchViewBudget(form.id)
      this.detail.projection = form.projection
      this.detail.year = year
    },
    goToList() {
      this.$router.push('/budgeting')
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2F80ED;
}

.remaining {
  color: #F7AC26;
}
</style>
